import React from "react"

import "./contact.scss"
import SEO from "../components/seo"

const ContactPage = () => {
  return (
    <div className="page-wrapper contact-page">
      <SEO title="Contact" />
      <div className="panel panel--1 panel--text flex flex-col">
        <h2 className="desktop-email">
          <a
            href="mailto:info@fridaycreative.nz?subject=Enquiry from Friday Creative website"
            target="_blank"
          >
            info@ friday creative .nz
          </a>
        </h2>
        <h2 className="mobile-contact-details">
          <a href="mailto:info@fridaycreative.nz?subject=Enquiry from Friday Creative website">
            info@fridaycreative.nz
          </a>
          <br />
          03 925 8199
          <br />
          021 33 22 38
        </h2>
        <div className=" flex-grow flex items-end">
          <div className="desktop-location">
            <p>Studio:</p>
            <p>
              5/245 St Asaph Street <br />
              SALT District <br />
              Christchurch 8011 <br />
              Right behind Little High Eatery <br />
              <br />© Friday Creative 2020
            </p>
          </div>
        </div>
      </div>
      <div className="panel panel--2 panel--text">
        <h2 className="desktop-phone-numbers">
          03 925 <br />
          8199 <br />
          <br />
          021 33 <br />
          22 38 <br />
        </h2>
        <div className="mobile-location">
          <p>Studio:</p>
          <p>
            5/245 St Asaph Street <br />
            SALT District <br />
            Christchurch 8011 <br />
            Right behind Little High Eatery <br />
            <br />© Friday Creative 2020
          </p>
        </div>
      </div>
    </div>
  )
}

export default ContactPage
